let first = document.querySelector('#buttonFirst')
let second = document.querySelector('#buttonSecond')
let third = document.querySelector('#buttonThird')
let firstEl = document.querySelector('#showFirst')
let secondEl = document.querySelector('#showSecond')
let thirdEl = document.querySelector('#showThird')
let mobFirstEl = document.querySelector('#mobShowFirst')
let mobSecondEl = document.querySelector('#mobShowSecond')
let mobThirdEl = document.querySelector('#mobShowThird')
let mobContainer = document.querySelector('#mob-container')
let mobButtonList = document.querySelector('#mob-button-list')

if (first.classList.value.split(' ').some((el) => el === 'active')) {
    mobContainer.classList.remove('benefits-container')
    mobContainer.classList.add('mob-first-benefits-container')
    mobButtonList.classList.remove('benefits-opportunities-list')
    mobButtonList.classList.add('mob-benefits-opportunities-list')
}


first.addEventListener('click', () => {
    setFirst()
})
second.addEventListener('click', () => {
    setSecond() 
})
third.addEventListener('click', () => {
    setThird() 
})

function setFirst() {
    if (!first.classList.value.split(' ').some((el) => el === 'active')) {
        first.classList.add('active')
        firstEl.classList.add('first-active')
        firstEl.classList.remove('second-active')
        firstEl.classList.remove('third-active')
        mobFirstEl.classList.add('first-active')
        mobFirstEl.classList.remove('second-active')
        mobFirstEl.classList.remove('third-active')
        mobContainer.classList.remove('benefits-container')
        mobContainer.classList.add('mob-first-benefits-container')
        mobButtonList.classList.remove('benefits-opportunities-list')
        mobButtonList.classList.add('mob-benefits-opportunities-list')
        if (second.classList.value.split(' ').some((el) => el === 'active')) {
            second.classList.remove('active')
            secondEl.classList.add('second-active')
            secondEl.classList.remove('first-active')
            secondEl.classList.remove('third-active')
            mobSecondEl.classList.add('second-active')
            mobSecondEl.classList.remove('first-active')
            mobSecondEl.classList.remove('third-active')
        }
        if (third.classList.value.split(' ').some((el) => el === 'active')) {
            third.classList.remove('active')
            thirdEl.classList.add('third-active')
            thirdEl.classList.remove('first-active')
            thirdEl.classList.remove('second-active')
            mobThirdEl.classList.add('third-active')
            mobThirdEl.classList.remove('first-active')
            mobThirdEl.classList.remove('second-active')
        }
    } 
}

function setSecond() {
    if (!second.classList.value.split(' ').some((el) => el === 'active')) {
        second.classList.add('active')
        secondEl.classList.add('first-active')
        secondEl.classList.remove('second-active')
        secondEl.classList.remove('third-active')
        mobSecondEl.classList.add('first-active')
        mobSecondEl.classList.remove('second-active')
        mobSecondEl.classList.remove('third-active')
        mobContainer.classList.add('benefits-container')
        mobContainer.classList.remove('mob-first-benefits-container')
        mobButtonList.classList.add('benefits-opportunities-list')
        mobButtonList.classList.remove('mob-benefits-opportunities-list')
        if (first.classList.value.split(' ').some((el) => el === 'active')) {
            first.classList.remove('active')
            firstEl.classList.add('third-active')
            firstEl.classList.remove('second-active')
            firstEl.classList.remove('first-active')
            mobFirstEl.classList.add('third-active')
            mobFirstEl.classList.remove('second-active')
            mobFirstEl.classList.remove('first-active')
        }
        if (third.classList.value.split(' ').some((el) => el === 'active')) {
            third.classList.remove('active')
            thirdEl.classList.add('second-active')
            thirdEl.classList.remove('first-active')
            thirdEl.classList.remove('third-active')
            mobThirdEl.classList.add('second-active')
            mobThirdEl.classList.remove('first-active')
            mobThirdEl.classList.remove('third-active')
        }
    } 
}

function setThird() {
    if (!third.classList.value.split(' ').some((el) => el === 'active')) {
        third.classList.add('active')
        thirdEl.classList.add('first-active')
        thirdEl.classList.remove('second-active')
        thirdEl.classList.remove('third-active') 
        mobThirdEl.classList.add('first-active')
        mobThirdEl.classList.remove('second-active')
        mobThirdEl.classList.remove('third-active') 
        mobContainer.classList.add('benefits-container')
        mobContainer.classList.remove('mob-first-benefits-container')
        mobButtonList.classList.add('benefits-opportunities-list')
        mobButtonList.classList.remove('mob-benefits-opportunities-list')
        if (first.classList.value.split(' ').some((el) => el === 'active')) {
            first.classList.remove('active')
            firstEl.classList.add('second-active')
            firstEl.classList.remove('third-active')
            firstEl.classList.remove('first-active')
            mobFirstEl.classList.add('second-active')
            mobFirstEl.classList.remove('third-active')
            mobFirstEl.classList.remove('first-active')
        }
        if (second.classList.value.split(' ').some((el) => el === 'active')) {
            second.classList.remove('active')
            secondEl.classList.add('third-active')
            secondEl.classList.remove('second-active')
            secondEl.classList.remove('first-active')
            mobSecondEl.classList.add('third-active')
            mobSecondEl.classList.remove('second-active')
            mobSecondEl.classList.remove('first-active')
        }
    } 
}
